<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white login-signup-on"
      
      id="kt_login"
    >
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-column flex-row-auto"
        style="background-color: #EBF6FFBF;"
      >
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <!--<a href="#" class="text-center mb-10">
            <img src="media/klik/logo.png" class="max-h-70px" alt=""/>
          </a>-->
           <div
				  class="d-flex flex-row-fluid bgi-no-repeat bgi-position-y-top bgi-position-x-center" style="background-size: 50%;height: 90px;"
				  :style="{ backgroundImage: `url(${LogoImage})` }"
			></div>
        </div>
        <div
          class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-top bgi-position-x-center" style="background-size: 100%;"
          :style="{ backgroundImage: `url(${backgroundImage})` }"
        ></div>
      </div>
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <div class="login-form login-signup">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signup_form"
              @submit.stop.prevent="onSubmit()"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                 {{$t('SIGNUP.MSG_REGISTER')}}
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  {{$t('SIGNUP.MSG_CREATE_STORE_NOW')}}
                </p>
              </div>
				<div class="form-group">
					<input class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6" type="text" :placeholder="$t('SIGNUP.MSG_SHOP_NAME')" name="fullname" ref="shopname" @blur="verifShop('shopname')" autocomplete="off"/>
				</div>
              <div class="form-group">
					<select ref="category" class="form-control form-control-lg form-control-solid" style="height: 69px;border-radius: 12px;">
						<option value="" disabled selected hidden><span style="color:#eee">{{$t('SIGNUP.MSG_CATEGORY')}}</span></option>
						<option v-for="item in categorySelect" :value="item.value" :key="item.value">
							{{ item.text }}
						</option>
						
					</select>
              </div>
			  <div class="form-group">
					<select ref="country" class="form-control form-control-lg form-control-solid" style="height: 69px;border-radius: 12px;" @change="CountryChange()">
						<option value="" disabled selected hidden><span style="color:#eee">{{$t('SIGNUP.MSG_COUNTRY')}}</span></option>
						<option v-for="item in countrySelect" :value="item.value" :key="item.value">
							{{ item.text }}
						</option>
					</select>
              </div>
			  <!--<div class="form-group">
                <input class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6" type="text" placeholder="Numéro de téléphone" name="phone" ref="phone" @blur="verifShop('phone')" autocomplete="off"/>
              </div>-->
				<div class="form-group row">
					<div class="col-lg-12 col-xl-12">
						<div class="input-group input-group-lg input-group-solid">
							<div class="input-group-prepend">
								<span class="input-group-text">
									<i class="la la-phone"></i> +({{dial_show}})
								</span>
							</div>
							<input type="text" style="height: 69px;" class="form-control form-control-lg form-control-solid" :placeholder="$t('SIGNUP.MSG_PHONE_NUMBER')"  name="phone" ref="phone" @blur="verifShop('phone')" autocomplete="off"/>
						</div>
					</div>
				</div>
              <div class="form-group">
                <input class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6" type="password" :placeholder="$t('SIGNUP.MSG_PASSWORD')" name="password" ref="rpassword" autocomplete="off" v-on:keyup='passwordValidation()'/>
					<b-alert show variant="warning" v-if='errorsPassword.length > 0'>
						<ul style="margin-left: 15px;">
							<li v-for='error in errorsPassword' v-bind:key="error">{{error}}</li>
						</ul>
					</b-alert>
				</div>
              <div class="form-group">
                <input class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6" type="password" :placeholder="$t('SIGNUP.MSG_CONFIRM_PASSWORD')" name="cpassword" ref="cpassword" autocomplete="off" />
              </div>
			   <div class="form-group">
                <input class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6" type="email" :placeholder="$t('SIGNUP.MSG_EMAIL')" name="email" ref="email" autocomplete="off" @blur="verifShop('email')"/>
              </div>
              <div class="form-group" v-html="$t('SIGNUP.MSG_TERMS_OF_USE')">
                  <!--En cliquant sur <b>Accepter et s’inscrire</b>, vous acceptez les <a href="https://try.doken.co/" class="ml-2"><b>Conditions d’utilisation</b></a> et la <b>Politique relative aux cookies</b>-->
              </div>
				<div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
					<!--<vue-recaptcha @verify="onVerify" @expired="onExpired" sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" ref="invisibleRecaptcha">
						<button  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4" style="width:150px;">Accepter et s’inscrire</button>
					</vue-recaptcha>-->
					<button
					  ref="kt_login_signup_submit"
					  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
					  style="width:100%;"
					>
						 <b-spinner v-if="SubmitAction"></b-spinner>
					 {{$t('SIGNUP.MSG_ACCEPT_AND_REGISTER')}}
					</button>
					<button style="width:100%;" type="button" id="kt_login_signup_cancel" class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3" @click="showSignIn()" >
						{{$t('SIGNUP.MSG_LOGIN')}}
					</button>
				</div>
            </form>
          </div>
          <!--end::Signup-->

          <!--end::Forgot-->
        </div>
        <!--begin::Content footer-->
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>
<script>
import axios from "axios";
import Swal from "sweetalert2";
//import VueRecaptcha from 'vue-recaptcha';
export default {
	name: "signup",
	//components: { VueRecaptcha },
	data: () => ({
		state: "signin",
		dial_show: "___",
		password: "",
		email: "",
		categorySelect: [],
		countrySelect: [],
		countrySelectById: [],
		verifShopError : false,
		SubmitAction : false,
		rules: [],
		errorsPassword:[],
	}),
    computed: {
    backgroundImage() {
      return (
        process.env.BASE_URL + "media/klik/Online-Shopping.png"
      );
    },
	LogoImage() {
      return (
        process.env.BASE_URL + "media/klik/logo.png"
      );
    }
  },
	mounted() {
		this.IpApi();
		axios.defaults.headers["Content-Type"] =  "application/json";
		axios.defaults.withCredentials = true;
		axios.defaults.baseURL = process.env.VUE_APP_APP_API;
		this.StartSignUp();
		this.rules = [
				{ message:this.$t('SIGNUP.MSG_ERROR_RULES_LOWERCASE'), regex:/[a-z]+/ },
				{ message:this.$t('SIGNUP.MSG_ERROR_RULES_UPPERCASE'),  regex:/[A-Z]+/ },
				{ message:this.$t('SIGNUP.MSG_ERROR_RULES_NUMBER'), regex:/.{8,}/ },
				{ message:this.$t('SIGNUP.MSG_ERROR_RULES_EMPTY'), regex:/[0-9]+/ }
			];
		//this.$refs.invisibleRecaptcha.execute()
	},
  methods: {
	CountryChange() {
		this.dial_show = this.countrySelectById[this.$refs.country.value].dial;
	},
	showSignIn() {
		this.$router.push('/login');
	},
	IpApi() {
		axios.post("/signin/geo").then((response) => {
			console.log(response.data);
		}).catch((errors) => {    
			console.log(errors);
		});
	},
	verifShop(type) {
		this.verifShopError = false;
		if(type == "shopname"){
			var shopname = this.$refs.shopname.value;
			if(shopname.length == 0){
				//this.SwalShow("",'Nom boutique existe deja',"error");
				this.verifShopError = true;
				return;
			}
			var data = {shop_name :shopname};
		}
		if(type == "email"){
			if(!this.EmailValidation()){
				return;
			}
			var email = this.$refs.email.value;
			data = {mail :email};
		}
		if(type == "phone"){
			var phone = this.$refs.phone.value;
			if(phone.length == 0){
				this.verifShopError = true;
				return;
			}
			data = {phone :phone};
		}
		axios.post("/signin/verif",data).then((response) => {
			if(response.data.shop != true && type == "shopname"){
				this.SwalShow(this.$t('APP_NAME'),this.$t('SIGNUP.MSG_SHOP_NAME_EXISTS'),"error");
				this.verifShopError = true;
			}else if(response.data.shop != true && type == "email"){
				this.SwalShow(this.$t('APP_NAME'),this.$t('SIGNUP.MSG_ERROR_MAIL_NAME_EXISTS'),"error");
				this.verifShopError = true;
			}else if(response.data.shop != true && type == "phone"){
				this.SwalShow(this.$t('APP_NAME'),this.$t('SIGNUP.MSG_ERROR_PHONE_NAME_EXISTS'),"error");
				this.verifShopError = true;
			}
			this.verifShopError = false;
			return;
		}).catch((errors) => {
			console.log(errors);
		});
	},
	passwordValidation () {
		this.errorsPassword = []
		for (let condition of this.rules) {
			if (!condition.regex.test(this.$refs.rpassword.value)) {
				this.errorsPassword.push(condition.message)
			}
		}
		if (this.errorsPassword.length === 0) {
			this.verifShopError = false;
			return this.errorsPassword;//{ valid:true, errors }
		} else {
			this.verifShopError = true;
			return this.errorsPassword;//{ valid:false, errors }
		}
	},
	EmailValidation () {
		var email = this.$refs.email.value;
		if(email.length > 0){
			if(!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email) ){
				this.SwalShow(this.$t('APP_NAME'),this.$t('SIGNUP.MSG_ERROR_MAIL_FORMAT'),"error");
				this.verifShopError = true;
				return false;
			} 
		}
		this.verifShopError = false;
		return true;
	},
	onSubmit() {
		
		var shopname = this.$refs.shopname.value;
		var category = this.$refs.category.value;
		var country = this.$refs.country.value;
		var email = this.$refs.email.value;
		var phone = this.$refs.phone.value;
		var rpassword = this.$refs.rpassword.value;
		var cpassword = this.$refs.cpassword.value;
		this.EmailValidation();
		this.passwordValidation();
		
		if(this.verifShopError){
			this.SwalShow(this.$t('APP_NAME'),this.$t('SIGNUP.MSG_ERROR_ALL_FIELDS'),"error");
			this.SubmitAction = false;
			return;
		}
		if(category == ""){
			this.SwalShow(this.$t('APP_NAME'),this.$t('SIGNUP.MSG_ERROR_CATEGORY_REQUIRED'),"error");
			this.SubmitAction = false;
			return;
		}
		if(country == ""){
			this.SwalShow(this.$t('APP_NAME'),this.$t('SIGNUP.MSG_ERROR_COUNTRY_REQUIRED'),"error");
			this.SubmitAction = false;
			return;
		}
		if(email.length > 0 && !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email) ){
			this.SwalShow(this.$t('APP_NAME'),this.$t('SIGNUP.MSG_ERROR_MAIL_FORMAT'),"error");
			this.SubmitAction = false;
			return;
		}

		if(phone == ""){
			this.SwalShow(this.$t('APP_NAME'),this.$t('SIGNUP.MSG_ERROR_PHONE_REQUIRED'),"error");
			this.SubmitAction = false;
			return;
		}
		if(rpassword == ""){
			this.SwalShow(this.$t('APP_NAME'),this.$t('SIGNUP.MSG_ERROR_PASSWORD_REPASSWORD'),"error");
			this.SubmitAction = false;
			return;
		}
		
		if(rpassword != cpassword){
			this.SwalShow(this.$t('APP_NAME'),this.$t('SIGNUP.MSG_ERROR_PASSWORD_REPASSWORD'),"error");
			this.SubmitAction = false;
			return;
		}

		var data = {shopname:shopname,category:category,country:country,email:email,phone:phone,password:rpassword};
		axios.post("/signin/send",data).then((response) => {
			this.SubmitAction = false;
			if(response.data.Signup == true){
				this.SwalShow(this.$t('SIGNUP.MSG_SEND_SMS_TEXT_TITLE'),this.$t('SIGNUP.MSG_SEND_SMS_TEXT_DESC'),"success");
				setTimeout(() => {
					this.$router.push('/verification');
				}, 4000);
			}else{
				this.SubmitAction = false;
				this.SwalShow(this.$t('APP_NAME'),this.$t('SIGNUP.MSG_ERROR_PRODUCT'),"error"); 
			}
		}).catch((errors) => { 
			this.SubmitAction = false;
			this.SwalShow(this.$t('APP_NAME'),this.$t('SIGNUP.MSG_ERROR_PRODUCT'),"error"); 
			console.log(errors);
		});
		return false;
    },
	StartSignUp() {
		axios.post("/signin/start").then((response) => {
			let str = [];
			let str1 = [];
			let str2 = [];
			Object.keys(response.data.Category).forEach(function(i) {
				str.push({value:response.data.Category[i].id_category_customer,text:response.data.Category[i].name});
			});
			Object.keys(response.data.Country).forEach(function(i) {
				if(response.data.Country[i].official_name_fr != "")
					str1.push({value:response.data.Country[i].id_country_currency,text:response.data.Country[i].official_name_fr+"("+response.data.Country[i].iso4217_currency_alphabetic_code +")"});
					str2[response.data.Country[i].id_country_currency] = response.data.Country[i];
			});
			//dial,iso4217_currency_name,official_name_fr
			this.categorySelect = str;
			this.countrySelect = str1;
			this.countrySelectById = str2;
			console.log(this.categorySelect);
		}).catch((errors) => {    
			console.log(errors)  ;
			/*localStorage.clear();				
			this.$router.push('/login');*/
		});
	},
    SwalShow(title,text,icon) {
		Swal.fire({
					title: title,
					text: text,
					icon: icon,
					confirmButtonClass: "btn btn-secondary",
					heightAuto: false
				}); 
	},

  }
};
</script>
